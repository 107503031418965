<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M6.75 6.75C7.64511 6.75 8.50355 6.39442 9.13649 5.76149C9.76942 5.12855 10.125 4.27011 10.125 3.375C10.125 2.47989 9.76942 1.62145 9.13649 0.988515C8.50355 0.355579 7.64511 0 6.75 0C5.85489 0 4.99645 0.355579 4.36351 0.988515C3.73058 1.62145 3.375 2.47989 3.375 3.375C3.375 4.27011 3.73058 5.12855 4.36351 5.76149C4.99645 6.39442 5.85489 6.75 6.75 6.75V6.75Z"
        :fill="fill"
    />
    <path
        d="M6.75 9C8.54021 9 10.2571 9.71116 11.523 10.977C12.7888 12.2429 13.5 13.9598 13.5 15.75H0C0 13.9598 0.711159 12.2429 1.97703 10.977C3.2429 9.71116 4.95979 9 6.75 9V9Z"
        :fill="fill"
    />
    <path
        d="M15.75 4.5C15.75 4.20163 15.6315 3.91548 15.4205 3.70451C15.2095 3.49353 14.9234 3.375 14.625 3.375C14.3266 3.375 14.0405 3.49353 13.8295 3.70451C13.6185 3.91548 13.5 4.20163 13.5 4.5V5.625H12.375C12.0766 5.625 11.7905 5.74353 11.5795 5.95451C11.3685 6.16548 11.25 6.45163 11.25 6.75C11.25 7.04837 11.3685 7.33452 11.5795 7.5455C11.7905 7.75647 12.0766 7.875 12.375 7.875H13.5V9C13.5 9.29837 13.6185 9.58452 13.8295 9.7955C14.0405 10.0065 14.3266 10.125 14.625 10.125C14.9234 10.125 15.2095 10.0065 15.4205 9.7955C15.6315 9.58452 15.75 9.29837 15.75 9V7.875H16.875C17.1734 7.875 17.4595 7.75647 17.6705 7.5455C17.8815 7.33452 18 7.04837 18 6.75C18 6.45163 17.8815 6.16548 17.6705 5.95451C17.4595 5.74353 17.1734 5.625 16.875 5.625H15.75V4.5Z"
        :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 16
    },
    fill: {
      type: String,
      default: '#9CA6BA'
    }
  }
}
</script>
